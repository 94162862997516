import React, {useState} from "react";
import {Backdrop, Box, CircularProgress, DialogContent, Snackbar, Stack, Tab, Typography} from "@mui/material";
import {HomeSetup} from "./EventSettings/HomeSetup";
import {ColorsSetup} from "./EventSettings/ColorsSetup";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Alert} from "../shared/Alert";

export const EventSettings = ({event, closeSettings}) => {
    const [value, setValue] = React.useState('1');
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSetSaving = (bool) => {
        setSaving(bool)
    }

    const handleSetSuccess = (bool) => {
        setSuccess(bool)
    }

    return (
        <DialogContent>
            <Stack direction={"column"}>
                <Snackbar open={success} autoHideDuration={2000} onClose={() => handleSetSuccess(false)}>
                    <Alert onClose={() => handleSetSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Impostazioni salvate con successo
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={saving}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography variant={"h4"}>Impostazioni</Typography>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab label="Schermata home" value="1" />
                            <Tab label="Colori" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"><HomeSetup handleSetSuccess={handleSetSuccess} handleSetSaving={handleSetSaving} closeSettings={closeSettings} event={event}/></TabPanel>
                    <TabPanel value="2"><ColorsSetup handleSetSuccess={handleSetSuccess} handleSetSaving={handleSetSaving} event={event} closeSettings={closeSettings}/></TabPanel>
                </TabContext>
            </Stack>
        </DialogContent>
    )
}
