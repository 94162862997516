import React from "react";
import {TextField} from "@mui/material";

export const ClickToForm = ({handleSetColore, colore}) => {
    return(
        <div>
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Colore"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={colore}
                    onChange={(e) => handleSetColore(e.target.value)}
                />
            </div>
        </div>
    )
}
