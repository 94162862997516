import React, {useState} from "react";
import {Button, ListItemIcon, ListItemText, MenuItem, Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {RotateLeft} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";
import {useParams} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResetQueueElementResultButton({elementId}) {
    const {id} = useParams()
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleResetResult() {
        setLoading(true)
        handleClose()
        await axios.delete(`${API_URL}/answer/${id}/${elementId}`)
            .then(r => {
                //setResults(r.data)
            })
            .catch(e => {
                //setError(true)
            })
            .finally(() => {
                setLoading(false)
            })

        console.log("Reset results", id)
    }

    return (
        <MenuItem onClick={handleClickOpen}>
            <ListItemIcon>
                <RotateLeft/>
            </ListItemIcon>
            <ListItemText>Resetta i risultati</ListItemText>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>Are you sure?</DialogTitle>
                <Button onClick={handleClose}>
                    No
                </Button>
                <Button onClick={handleResetResult}>
                    Yes
                </Button>
            </Dialog>
        </MenuItem>
    )
}
