import React, {useEffect, useState} from "react";
import {Box, Button, capitalize, Checkbox, TextareaAutosize, TextField, Typography} from "@mui/material";
import {firestore} from "../../../firebase/clientApp";
import {FileUploader} from "react-drag-drop-files";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Upload} from "@mui/icons-material";
import {API_URL} from "../../../config";

const fileTypes = ["JPEG", "PNG", "JPG"];

export const HomeSetup = ({event, closeSettings, handleSetSaving, handleSetSuccess}) => {
    const {id} = useParams()
    const [home, setHome] = useState({})
    const [contenutoAgenda, setContenutoAgenda] = useState('')
    const [titoloAgenda, setTitoloAgenda] = useState('')
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState('')
    const handleChangeLogo = (file) => {
        setLogo(file);
        setLogoUrl(URL.createObjectURL(file))
    };

    useEffect(() => {
        setHome({
            applausometro: event.data().applausometro,
            agenda: event.data().agenda,
            logo: event.data().logo
        })
        setContenutoAgenda(event.data().contenutoAgenda)
        setTitoloAgenda(event.data().titoloAgenda)
        setLogoUrl(event.data().logoUrl)
    }, [event])

    const handleSetHome = (e, field) => {
        setHome({...home, [field]: e.target.checked})
    }

    const changeAgenda = (e) => {
        setContenutoAgenda(e.target.value)
    }

    const changeAgendaTitle = (e) => {
        setTitoloAgenda(e.target.value)
    }

    const saveSettings = async (e) => {
        handleSetSaving(true)
        const formData = new FormData();
        formData.append('file', logo);
        if (logo !== null) {
            await axios({
                url: `${API_URL}/events/${event.id}/logo`,
                method: 'POST',
                data: formData,
            }).then()
        }
        await firestore.doc('users/plesh/events/' + event.id).update({
            applausometro: home.applausometro,
            agenda: home.agenda,
            logo: home.logo,
            contenutoAgenda,
            titoloAgenda
        }).then(handleSetSuccess(true))
        handleSetSaving(false)

    }

    return (
        <Box sx={{width: '100%'}}>
            <Typography variant={"h5"}>Home</Typography>
            {Object.keys(home).map(o =>
                <Box sx={{width: '100%', py: 1}} key={o}>
                    <Typography variant={"h6"}>{capitalize(o)}</Typography>
                    <Box>
                        <Checkbox checked={home[o]} onChange={(e) => handleSetHome(e, o)}/> Visibile
                    </Box>
                    {o === 'logo' &&
                        <Box>
                            <Typography variant={"body2"} sx={{mb: 1}}>Logo:</Typography>
                            <FileUploader
                                multiple={false}
                                handleChange={handleChangeLogo}
                                name="file"
                                types={fileTypes}
                                children={

                                    <Box sx={{
                                        width: '100%',
                                        maxWidth: '20rem',
                                        p: 2,
                                        border: '2px dashed white',
                                        borderRadius: '1rem',
                                        textAlign: 'center'
                                    }}>
                                        {logoUrl !== '' ?
                                            <Box>
                                                <img src={logoUrl} style={{width: '100%'}}/>
                                                <Typography variant={"body2"} sx={{mb: 1}}>Clicca qui per sostituire il
                                                    tuo logo</Typography>
                                                <Upload sx={{fontSize: '3rem'}}/>
                                            </Box>
                                             :
                                            <Box>
                                                <Typography variant={"body2"} sx={{mb: 1}}>Clicca qui per caricare il
                                                    tuo logo</Typography>
                                                <Upload sx={{fontSize: '3rem'}}/>
                                            </Box>
                                        }
                                    </Box>
                                }
                            />
                        </Box>
                    }
                    {o === 'agenda' &&
                        <Box>
                            <Box sx={{mb: 2}}>
                                <TextField label={'Titolo blocco'} onChange={changeAgendaTitle} value={titoloAgenda}
                                           variant={'standard'} style={{width: '100%'}}/>
                            </Box>
                            <Box>
                                <Typography sx={{mb: 1}} variant={"body1"}>Contenuto agenda:</Typography>
                                <TextareaAutosize onChange={changeAgenda} className={'agendaArea'} minRows={7}
                                                  value={contenutoAgenda} style={{resize: 'none', width: '100%'}}/>
                            </Box>
                        </Box>
                    }
                </Box>
            )}
            <Box>
                <Button variant={"contained"} onClick={saveSettings}>Salva</Button>
                <Button onClick={() => closeSettings()}>Chiudi</Button>
            </Box>
        </Box>
    )
}
