import React from "react";
import {Box, ListItemIcon, ListItemText, MenuItem, Stack} from "@mui/material";
import {EmojiEmotions, Equalizer, Groups, MilitaryTech, ThumbUp} from "@mui/icons-material";
import {Link} from "react-router-dom";

export const PagesButtons = ({id}) => {
    const  buttons = [
        {
            label: 'Grafico domanda attiva',
            href: '/control/'+id+'/domanda',
            icon: <Equalizer/>
        },
        {
            label: 'Classifica Personale',
            href: '/results/'+id,
            icon: <MilitaryTech/>
        },
        {
            label: 'Classifica Squadre',
            href: '/team-results/'+id,
            icon: <Box mr={2}><MilitaryTech/><Groups/></Box>
        },
        {
            label: 'Grafico applausi',
            href: '/control/'+id+'/grafici',
            icon: <ThumbUp/>
        },
        {
            label: 'Numero applausi',
            href: '/control/'+id+'/applausi',
            icon: <ThumbUp/>
        },
        {
            label: 'Grafico reazioni',
            href: '/control/'+id+'/reactions',
            icon: <EmojiEmotions/>
        }
    ]

    return (
        <Stack direction={'column'} justifyContent={"flex-start"} spacing={1}>
            {buttons.map((button, index) =>
                <MenuItem key={index} variant={"outlined"} component={Link} to={button.href} target={'_blank'}>
                    <ListItemIcon>{button.icon}</ListItemIcon>
                    <ListItemText>{button.label}</ListItemText>
                </MenuItem>
            )}
        </Stack>
    )
}
