import React, {useEffect, useState} from "react";
import {useObject} from "react-firebase-hooks/database";
import {ref} from "firebase/database";
import {db} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import clap from '../assets/images/reactions/clap.png'
import laugh from '../assets/images/reactions/laugh.png'
import {useQuery} from "../custom-hooks/useQuery";
import {CSSTransition, TransitionGroup} from 'react-transition-group';

export const ReactionsVisual = () => {
    const {id} = useParams()
    const query = useQuery()
    const type = query.get('type') ?? 'applausi'
    const dim = query.get('dim')

    const [snapshots, loading, error] = useObject(ref(db, `users/plesh/events/${id}/${type}`));

    const [spawnedImages, setSpawnedImages] = useState([]);

    useEffect(() => {
        if (snapshots) {
            const value = snapshots.val();
            if (value) {
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;
                const randomX = Math.floor(Math.random() * (screenWidth - 200));
                const randomY = Math.floor(Math.random() * (screenHeight - 200));
                const newImage = {
                    id: Date.now(),
                    position: {x: randomX, y: randomY},
                    src: reactions.find((o) => {
                        return o.id === type
                    })?.img ?? clap
                };

                if (spawnedImages.length < 40) {
                    setSpawnedImages((prevImages) => [...prevImages, newImage]);

                    setTimeout(() => {
                        setSpawnedImages((prevImages) =>
                            prevImages.filter((image) => image.id !== newImage.id)
                        );
                    }, 3000);
                }
            }
        }
    }, [snapshots]);

    return (
        <TransitionGroup>
            {spawnedImages.map((image) => (
                <CSSTransition
                    key={image.id}
                    timeout={400}
                    classNames="image"
                    unmountOnExit
                >
                    <img
                        key={image.id}
                        src={image.src}
                        alt="Spawned Image"
                        style={{
                            position: 'absolute',
                            left: image.position.x,
                            top: image.position.y,
                            //transform: `rotate(${Math.floor(Math.random() * (90 + 1))}deg)`,
                            width: `${dim ?? 50}px`,
                            height: `${dim ?? 50}px`,
                        }}
                    />
                </CSSTransition>
            ))}
        </TransitionGroup>
    )
}

const reactions = [
    {
        id: 'applausi',
        emoji: '👏',
        img: clap
    },
    {
        id: 'laugh',
        emoji: '🤣',
        img: laugh
    }
]