import React, {useEffect, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import {CategoryColorSetup} from "./CategoryColorSetup";
import {firestore} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useCollection} from "react-firebase-hooks/firestore";
import {baseTheme} from "../../../config";


export const ColorsSetup = ({event, closeSettings, handleSetSaving, handleSetSuccess}) => {
    const {id} = useParams()
    const [themes, loading, error] = useCollection(firestore.collection('users/plesh/events/'+id+'/themes'))
    const [localThemes, setLocalThemes] = useState([])

    useEffect(() => {
        if (themes && !loading && !error) {
            let localThemes = themes.docs.map(t => ({...t.data(), id:t.id}))
            setLocalThemes(localThemes)
        }
    }, [themes])

    const handleUpdateColor = (id, category, color, index) => {
        let newThemes = localThemes.map(t => {
            if (t.id === id) {
                (index != null) ?
                    t[category][index] = color :
                    t[category] = color
            }
            return t
        })
        setLocalThemes(newThemes)
    }

    const handleUpdateTheme = () => {
        localThemes.map(async t => {
            await firestore
                .collection('users/plesh/events/'+ id +'/themes')
                .doc(t.id)
                .set({colors:t.colors, background: t.background, text: t.text}, {merge: true})
                .then()
        })
    }

    const resetTheme = (themeId) => {
        let newThemes = localThemes.map(t => {
            if (t.id === themeId) {
                return  {
                    colors:[...baseTheme.colors],
                    text:baseTheme.text,
                    background:baseTheme.background,
                    created_at: t.created_at,
                    editable: t.editable,
                    id:t.id
                }
            } else return t
        })
        setLocalThemes(newThemes)
    }

    return (
        <Box sx={{width: '100%'}}>
            <Stack sx={{minHeight:'15rem'}}>
                {(!loading && !error) &&
                    localThemes.map(t =>
                        <CategoryColorSetup key={t.id} theme={t} handleUpdateColor={handleUpdateColor} resetTheme={resetTheme}/>
                    )}
            </Stack>
            <Box sx={{mt:2}}>
                <Button variant={"contained"} onClick={handleUpdateTheme}>Salva</Button>
                <Button onClick={()=> closeSettings()}>Chiudi</Button>
            </Box>
        </Box>
    )
}
