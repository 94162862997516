import React from 'react'
import {TextField} from "@mui/material";
import QuizForm from "./FormDomande/QuizForm";
import {ClickToForm} from "./FormDomande/ClickToForm";
import Box from "@mui/material/Box";
import {ToolpressForm} from "./FormDomande/ToolpressForm";

export default function AddQueueElementForm({
                                                type,
                                                handleSetDomanda,
                                                handleSetColore,
                                                handleSetRisposte,
                                                risposte,
                                                handleSetCorretta,
                                                corretta,
                                                hideResult,
                                                handleHideResult,
                                                colore,
                                                handleSetConferenza,
                                                conferenza
}) {

    function setDomanda(event) {
        handleSetDomanda(event.target.value)
    }

    return(
        <Box>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Testo domanda"
                type="text"
                fullWidth
                required
                variant="standard"
                onChange={setDomanda}
            />
            {(() => {
                switch (type) {
                    case 'clickTo':
                        return <ClickToForm handleSetColore={handleSetColore} colore={colore}/>
                    case 'quiz':
                        return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} handleSetCorretta={handleSetCorretta} corr={corretta} hideResult={hideResult} handleHideResult={handleHideResult}/>
                    case 'poll':
                    case 'multiPoll':
                        return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} poll={true}/>
                    case 'toolpress':
                        return <ToolpressForm handleSetConferenza={handleSetConferenza} conferenza={conferenza}/>
                    default:
                        return null
                }
            })()}
        </Box>
    )
}
