import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import {sendPhotoOptions} from "../../../assets/data/documentation/sendPhotoOptions";
import {SimulatorField} from "./SimulatorField";
import {wordcloudOptions} from "../../../assets/data/documentation/wordcloudOptions";

const options = [
    ...wordcloudOptions,
    ...sendPhotoOptions
]

export const SendPhotoSimulator = ({handleSetParam, handleInitializeParams}) => {

    useEffect(() => {
        handleInitializeParams(options)
    }, [])

  return(
      <Box>
          <Stack spacing={2}>
              {
                  options.map((option, index) =>
                    <SimulatorField handleSetParam={handleSetParam} field={option}/>
                  )
              }
          </Stack>
      </Box>
  )
}