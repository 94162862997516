import React, {useEffect} from "react";
import {Box, Chip, Container, Stack, Typography} from "@mui/material";
import axios from "axios";
import {API_URL} from "../config";
import {useParams} from "react-router-dom";
import {Groups} from '@mui/icons-material';

export const RankResults = ({team}) => {
    const {id} = useParams()
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)

    useEffect(() => {
        getResults()
    }, [])

    const getResults = async () => {
        setLoading(true)
        await axios.get(!!team ? `${API_URL}/team-result/${id}` : `${API_URL}/result/${id}`)
            .then(r => {
                setResults(r.data.filter((r) => {
                    return !!r.name || (team && !!r.teamName)
                }))
            })
            .catch(e => {
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    if (loading) return (<Box>Loading...</Box>)

    return (
        <Container sx={{py: 2}}>
            {results.map((r, i) =>
                <Box key={i} sx={{py:1}}>
                    <Stack direction={'row'} alignItems={'center'} spacing={4}>
                        <Box sx={{width:'3rem'}}>
                            <Typography variant={'h4'}>
                                {++i}{/*r.position*/}
                            </Typography>
                        </Box>
                        <Box>
                                 <Stack direction={'row'} spacing={1}>
                                    {!team && <Typography variant={'h5'}>
                                        {r.name}
                                    </Typography>}
                                     {r.teamName &&
                                         <Chip sx={{px: 1, fontSize: team ? '1.25rem' : '0.8125rem'}}
                                               label={`TEAM ${r.teamName}`} icon={<Groups/>}/>}
                                </Stack>
                            <Typography>
                                Punteggio {r.points}
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            )}
        </Container>
    )
}