export const wordcloudOptions = [
    {
        param: "padding",
        name: "Padding",
        type: "number",
        default: 2,
    },
    {
        param: "uppercase",
        name: "Uppercase",
        type: "radio",
        default: "false",
        options: [
            {
                label: "Si",
                value: "true"
            },
            {
                label: "No",
                value: "false"
            }
        ]
    },
    {
        param: "rotation",
        name: "Rotazione",
        type: "radio",
        default: "false",
        options: [
            {
                label: "Si",
                value: "true"
            },
            {
                label: "No",
                value: "false"
            }
        ]
    },
    {
        param: "min",
        name: "Dimensione minima del font",
        type: "number",
        default: 30,
    },
    {
        param: "max",
        name: "Dimensione massima del font",
        type: "number",
        default: 100,
    },
    {
        param: "font",
        name: "Font",
        type: "select",
        default: "Roboto",
        options: [
            {
                value: "Roboto",
                label: "Roboto",
            },
            {
                value: "Helvetica",
                label: "Helvetica",
            },
            {
                value: "Arial",
                label: "Arial",
            },
            {
                value: "Arial Black",
                label: "Arial Black",
            },
            {
                value: "Verdana",
                label: "Verdana",
            },
            {
                value: "Tahoma",
                label: "Tahoma",
            },
            {
                value: "Trebuchet MS",
                label: "Trebuchet MS",
            },
            {
                value: "Impact",
                label: "Impact",
            },
            {
                value: "Gill Sans",
                label: "Gill Sans",
            },
            {
                value: "Times New Roman",
                label: "Times New Roman",
            },
            {
                value: "Titillium Web",
                label: "Titillium Web",
            },
            {
                value: "Georgia",
                label: "Georgia",
            },
            {
                value: "Palatino",
                label: "Palatino",
            },
            {
                value: "Baskerville",
                label: "Baskerville",
            },
            {
                value: "Andalé Mono",
                label: "Andalé Mono",
            },
            {
                value: "Courier",
                label: "Courier",
            },
            {
                value: "Lucida",
                label: "Lucida",
            },
            {
                value: "Monaco",
                label: "Monaco",
            },
            {
                value: "Bradley Hand",
                label: "Bradley Hand",
            },
            {
                value: "Brush Script MT",
                label: "Brush Script MT",
            },
            {
                value: "Luminari",
                label: "Luminari",
            },
            {
                value: "Comic Sans MS",
                label: "Comic Sans MS",
            }
        ]
    },
    {
        param: "mode",
        name:"Modalità",
        type: "select",
        default: "normal",
        options: [
            {
                value: "normal",
                label: "Normale"
            },
            {
                value: "luma",
                label: "Luma"
            }
        ]
    }
]