import {useParams} from "react-router-dom";
import {useQuery} from "../../../custom-hooks/useQuery";
import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {Box, Button, Chip, Container, Stack, TextField, Typography} from "@mui/material";
import {Groups} from "@mui/icons-material";

const ReserveSpotVisual = ({result, elementPlaying}) => {
    const {id} = useParams()
    const query = useQuery()
    const control = query.get('control') === 'true'

    const [incrementPoints, setIncrementPoints] = useState('20')

    const handleAddPoints = (event) => {
        axios.put(`${API_URL}/answer/${id}/${elementPlaying.id}/${event.target.id}/points`, {
            incrementPoints: incrementPoints
        })
            .then(response => console.log("boosted", response))
            .catch( error => console.log("error boosting",error))
    }

    return (<Container sx={{py: 2}}>
        {result?.data?.map((r, i) =>
            <Box key={i} sx={{py:1}}>
                <Stack direction={'row'} alignItems={'center'} spacing={4}>
                    <Box sx={{width:'3rem'}}>
                        <Typography variant={'h4'}>
                            {r.position || (++i)}
                        </Typography>
                    </Box>
                    <Box>
                        <Stack direction={'row'} spacing={1}>
                            <Typography variant={'h5'}>
                                {r.displayName}
                            </Typography>
                            {r.teamName && <Chip sx={{px: 1, fontSize: '0.8125rem'}} label={`TEAM ${r.teamName}`} icon={<Groups/>}/>}
                        </Stack>
                        {control && <Typography>
                            {`Punteggio domanda: ${r.points}`}
                        </Typography>}
                    </Box>
                    {
                        control &&
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <TextField
                                type={'number'}
                                defaultValue={20}
                                value={incrementPoints}
                                onChange={(event) => {
                                    setIncrementPoints(event.target.value);
                                }}
                            >

                            </TextField>
                            <Button id={r.id} onClick={handleAddPoints} disabled={incrementPoints === '0'}>
                                Aggiungi punti ({incrementPoints})
                            </Button>
                        </Stack>
                    }
                </Stack>
            </Box>
        )}
    </Container>);
}

export default ReserveSpotVisual